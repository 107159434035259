import './footer.css'
import { Header } from './../../components/header/header'
import topLink from './../../img/icon/top.svg'
import Phone from './../../img/icon/phone_icon.svg'
import Map from './../../img/icon/map.svg'
import CompanyLogo from './../../img/icon/companyLogo.svg'
import RusitLogo from './../../img/icon/rusit.svg'
// $(document).ready(function () {
// 	$('.scrollTop_icon').on('click', function () {
// 		var body = $('html, body')
// 		body.stop().animate({ scrollTop: 0 }, 500, 'swing', function () {})
// 	})
// //	 $('.footer #nav a').on('click', function () {
// //		 var body = $('html, body')
// //		 body.stop().animate({ scrollTop: 0 }, 0, 'swing', function () {})
// //	 })

// //	 $('.open_menu img').on('click', function () {
// //		 $('.mobile_menu').fadeIn()
// //	 })
// //	 $('.close_Mobile_menu img').on('click', function () {
// //		 $('.mobile_menu').fadeOut()
// //	 })
// })
const Footer = () => {
	return (
		<section className="footer">
			<Header />
			<div className="content">
				<div className="inn">
					<div>
						<p>ООО «ГК ОКТЕН» © 2024</p>
						<span>
							ИНН: 7743453370
							<br />
							ОГРНИП: 1247700470929
						</span>
					</div>
					<div className="scrollTop">
						<img src={topLink} alt="Наверх" className="scrollTop_icon" />
					</div>
				</div>
				<div className="footerContact">
					<div className="footerContact_tel foot_item">
						<img src={Phone} alt="Телефон" />
						<div className="footerPhone">
							<b>Телефон</b>
							<a href="tel:+7 (495) 123-35-77">+7 (495) 123-35-77</a>
						</div>
					</div>
					<div className="footerContact_map foot_item">
						<img src={Map} alt="Карта" />
						<div className="footerMap">
							<b>Мы находимся по адресу:</b>
							<span>Тверская область, Конаковский район, д. Старое Мелково, ул. Центральная 6</span>
						</div>
					</div>
					<img src={CompanyLogo} alt="Логотип бренда" className="CompLogo_foot" />
					<div>
						<a href="https://rusit-po.ru/" target="_blanc" className="rusit">
							<img src={RusitLogo} alt="Лого РУСИТ" />
							<span>Сайт разработан компанией Rusit</span>
						</a>
					</div>
				</div>
			</div>
		</section>
	)
}

export { Footer }
